const ONBOARDING_TASK_CODENAME = {
    ANTI_MONEY_LAUNDERING: 'ANTI_MONEY_LAUNDERING',
    COMPANY_REGISTRATION_REPORT: 'COMPANY_REGISTRATION_REPORT',
    CONSULTANT_CANCELLATION_DATE: 'CONSULTANT_CANCELLATION_DATE',
    CSO_730_MANDATE: 'CSO_730_MANDATE',
    DOCUMENT_COLLECTION: 'DOCUMENT_COLLECTION',
    EXTRA_MONTHLY_PAY: 'EXTRA_MONTHLY_PAY',
    F24_MANAGEMENT: 'F24_MANAGEMENT',
    F24_MANDATE: 'F24_MANDATE',
    IBAN: 'IBAN',
    LEGAL_REPRESENTATIVE: 'LEGAL_REPRESENTATIVE',
    LUL_F24_PROSPECTS: 'LUL_F24_PROSPECTS',
    MAINTAIN_LUL_PEC_SENDING_DATE: 'MAINTAIN_LUL_PEC_SENDING_DATE',
    MANDATES: 'MANDATES',
    PAYROLL_DATES: 'PAYROLL_DATES',
    PREVIOUS_LUL: 'PREVIOUS_LUL',
    WORKFORCE_DATA: 'WORKFORCE_DATA',
};

const ONBOARDING_TASK_STATUS = {
    TO_SET: 'TO_SET',
    TODO: 'TODO',
    UNDER_APPROVAL: 'UNDER_APPROVAL',
    DONE: 'DONE',
};

export {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS};
